import $ from 'jquery'
import Swiper from 'swiper/bundle'
import {isMobile} from './helpers/helper'

export const singleSlideSlider = () => {
  if (!$('.row-slider').length) return

  $('.row-slider').each((index, element) => {
    const total_slides = $(element).find('.swiper-slide').length
    const slider_prev = $(element)
      .closest('.section-block-row')
      .find('.slider-prev')
      .addClass(`template-prev-${index}`)
    const slider_next = $(element)
      .closest('.section-block-row')
      .find('.slider-next')
      .addClass(`template-next-${index}`)

    return new Swiper(element, {
      slidesPerView: 'auto',
      speed: 800,
      watchSlidesVisibility: 1,
      slideActiveClass: 'active',
      observer: true,
      observeParents: true,
      loop: true,
      loopedSlides: total_slides,
      navigation: {
        nextEl: `.template-next-${index}`,
        prevEl: `.template-prev-${index}`,
      },
      autoplay: {
        delay: 4500 + index * 500,
      },
    })
  })
}

export const colSlider = () => {
  if (!$('.col-slider').length) return

  $('.col-slider').each((index, element) => {
    const total_slides = $(element).find('.swiper-slide').length
    const slider_prev = $(element)
      .closest('.section-block-row')
      .find('.slider-prev')
      .addClass(`template-prev-${index}`)
    const slider_next = $(element)
      .closest('.section-block-row')
      .find('.slider-next')
      .addClass(`template-next-${index}`)

    if (isMobile()) {
      mobileLayout()
      colSliderInit()
    } else {
      colSliderInit()
    }

    // return

    function mobileLayout() {
      const el = $(element).find('.col-slide-m')
      el.addClass('swiper-slide')
      el.slice(0).appendTo('.col-slider-wrap')
      $('.col-slide-d').remove()
    }

    function colSliderInit() {
      return new Swiper(element, {
        slidesPerView: 'auto',
        speed: 800,
        watchSlidesVisibility: 1,
        slideActiveClass: 'active',
        observer: true,
        observeParents: true,
        loop: true,
        loopedSlides: total_slides,
        navigation: {
          nextEl: `.template-next-${index}`,
          prevEl: `.template-prev-${index}`,
        },
        autoplay: {
          delay: 4500 + index * 500,
        },
      })
    }
  })
}
